'use client'
import React, { useState, useEffect, useRef } from 'react'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { AiOutlineLayout, AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { BiSolidBed, BiSolidBath } from "react-icons/bi"
import { RiLayoutGridLine  } from "react-icons/ri"
import { IoBed } from 'react-icons/io5'
import { TbBathFilled } from 'react-icons/tb'
import tailwindConfig from '@tailwind.config'
import { sendLike } from '@services/sendLike'
import CurrencySymbol from '@components/CurrencySymbol'
import LinkComponent from './LinkComponent'
import { IoLocationOutline } from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

const PropertyCard = ({ property, imageWidth = 300, imageHeight = 200, newLimit, gallerySlider = false, cardType, isLast, dictionary, lang }) => {
    const [showLikeBtn, setShowLikeBtn] = useState(false)
    const [isLiked, setIsLiked] = useState(property.isLiked)
    const { data: session } = useSession()

    const cardRef = useRef();

    const handleLike = async () => {
        const isLike = await sendLike(property.id, session?.user?.email)
        setIsLiked((prevLike) => !prevLike)
        property.isLiked = !property.isLiked
    };

    useEffect(() => {
        if (!cardRef?.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (isLast && entry.isIntersecting) {
                newLimit();
                // nextPage()
                observer.unobserve(entry.target);
            }
        });

        observer.observe(cardRef.current);
    }, [isLast]);

    useEffect(() => {
        if (session && session.user !== null) {
            setShowLikeBtn(true)
        }
    }, [])

    return (
        <div ref={cardRef} className='w-full border-[1px] border-gray-200 rounded-xl'>
            <LinkComponent
                className='w-full'
                href={'/properties/' + property.slug}
                
            >
                <div className='relative w-full  aspect-[4/3] rounded-t-xl overflow-hidden'>
                    {(showLikeBtn == true && isLiked == false) && (
                        <div title="Left-click on this icon to save this property" onClick={handleLike} className='absolute flex flex-row flex-wrap top-1 right-1 z-40 h-8 w-8 justify-center items-center border-[1px] border-secondary rounded-md p-1'>
                            <AiOutlineHeart
                                size={24}
                                color={tailwindConfig.theme.extend.colors.secondary}
                            />
                        </div>
                    )}
                    {(showLikeBtn == true && isLiked == true) && (
                        <div title="Left-click to unlike this property" onClick={handleLike} className='absolute flex flex-row flex-wrap top-1 right-1 z-40 h-8 w-8 justify-center items-center border-[1px] border-third rounded-md p-1'>
                            <AiFillHeart
                                size={24}
                                color={tailwindConfig.theme.extend.colors.third}
                            />
                        </div>
                    )}
                    {gallerySlider == true ? (
                        <Swiper
                            slidesPerView={1}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Navigation]}
                            className='w-full h-full new'
                        >
                            {property.gallery?.map((image, index) => (
                                <SwiperSlide key={index} className='new'>
                                    <Image
                                        placeholder="blur"
                                        blurDataURL={property.blur}
                                        // className='object-fit'
                                        className='object-cover'
                                        src={image}
                                        // width={imageWidth}
                                        // height={imageHeight}
                                        fill={true}
                                        alt={property.slug}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <Image
                            placeholder="blur"
                            blurDataURL={property.blur}
                            // className='object-fit'
                            className='object-cover'
                            src={property.cover}
                            // width={imageWidth}
                            // height={imageHeight}
                            fill={true}
                            alt={property.slug}
                        />
                    )}
                    {property.publicTransport ? (
                        <div className='absolute flex flex-row flex-wrap bottom-0 left-0'>
                            {property.publicTransport.map((transport, index) => (
                                <div key={index} style={{ backgroundColor: transport.colorCode }} className={`rounded-sm px-1 text-light text-sm ml-1 mb-1`}>{transport.type.toUpperCase()} {transport.transportName?.[lang] ? transport.transportName[lang] : transport.stationName}</div>
                            ))}
                        </div>
                    ) : null}
                    {cardType == 'promoted' && (
                        <div className={`absolute left-2 top-2 bg-third text-maincolor font-semibold border-[1px] border-maincolor shadow-md shadow-maincolor/30 py-1 px-2 text-md rounded-full z-10`}><span>{property.promotionTitle}</span></div>
                    )}
                    <div className={`absolute right-2 bottom-2 bg-white text-maincolor font-semibold border-[1px] border-maincolor shadow-md shadow-maincolor/30 py-1 px-2 text-xs rounded-full z-10`}><span>{property.ref}</span></div>
                </div>
                {/* <div className='absolute left-2 top-2 bg-maincolor text-light py-1 px-2 text-md rounded-full z-10'><h3>{property.category}{dictionary.for}{property.saleType}</h3></div> */}
                <div className='p-3'>
                    <div className='flex flex-col h-[70px]'>
                        {property.salePrice !== undefined && (
                            <div className='flex flex-row items-center justify-between pb-2'>
                                <div className='text-xl font-semibold text-third'><CurrencySymbol lang={lang} />{property.salePrice}</div>
                                <div className='rounded-full bg-third text-white px-2'>{dictionary.sale}</div>
                            </div>
                        )}
                        {property.rentPrice !== undefined && (
                            <div className='flex flex-row items-center justify-between pb-2'>
                                <div className='text-xl font-semibold text-third'><CurrencySymbol lang={lang} />{property.rentPrice}{`/${dictionary.month}`}</div>
                                <div className='rounded-full border-third border-[1px] bg-third/10 text-third px-2'>{dictionary.rent}</div>
                            </div>
                        )}
                    </div>
                    <div className='h-[50px] pb-2 mb-2'>
                        <span className='font-semibold line-clamp-2'>{property.name}</span>
                    </div>
                    {property.metaCategory.toLowerCase() == 'land' ? (
                        <div className='flex flex-row py-2 border-t-[1px] border-t-gray-300'>
                            {/* <div className='flex flex-row items-center mr-1'>
                                <span className='mr-1 text-maincolor'>{property.category} |</span>
                            </div> */}
                            <div className='flex flex-row items-center'>
                                <span className='mr-1 text-maincolor'>{property.landArea}{dictionary.sqm}</span>
                                <RiLayoutGridLine
                                    color={tailwindConfig.theme.extend.colors.secondary}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='w-full flex flex-row items-center justify-start py-2 border-t-[1px] border-t-gray-300'>
                            {/* <div className='flex flex-row items-center mr-1'>
                                <span className='mr-1 text-maincolor'>{property.category} |</span>
                            </div> */}
                            {property.metaCategory.toLowerCase() === 'condo' && property.subcategory?.toLowerCase() === 'studio' && (
                                <div className='w-1/4 flex flex-row items-center mr-2'>
                                    <span className='mr-1 text-maincolor'>{dictionary.studio}</span>
                                    <IoBed
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                </div>
                            )}
                            {(property.metaCategory.toLowerCase() === 'condo' || property.metaCategory.toLowerCase() === 'house' || property.metaCategory.toLowerCase() === 'poolvilla') && property.subcategory?.toLowerCase() !== 'studio'  && property.bedrooms!== undefined && property.bedrooms > 0 &&
                                <div className='w-1/4 flex flex-row items-center mr-2'>
                                    <span className='mr-1 text-maincolor'>{property.bedrooms}</span>
                                    <IoBed
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                </div>
                            }
                            {(property.metaCategory.toLowerCase() === 'condo' || property.metaCategory.toLowerCase() === 'house' || property.metaCategory.toLowerCase() === 'poolvilla') && property.bathrooms!== undefined && property.bathrooms > 0 &&
                                <div className='w-1/4 flex flex-row items-center mr-2'>
                                    <span className='mr-1 text-maincolor'>{property.bathrooms}</span>
                                    <TbBathFilled
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                </div>
                            }
                            {(property.livingArea !== undefined && property.livingArea > 0) && (
                                <div className='w-1/4 flex flex-row items-center'>
                                    <span className='mr-1 text-maincolor'>{property.livingArea}{dictionary.sqm}</span>
                                    <AiOutlineLayout
                                        color={tailwindConfig.theme.extend.colors.secondary}
                                    />
                                </div>
                            )}
                        </div>
                    )
                    }

                    <div className='flex flex-row items-center border-t-[1px] border-t-gray-300 py-1'>
                        <IoLocationOutline 
                            color={tailwindConfig.theme.extend.colors.mainhover} 
                        />
                        <span className='ml-1 text-mainhover'>{property.districts[0]?.subdistrictName?.[lang] ? property.districts[0].subdistrictName[lang] : property.districts[0]?.subdistrict}, {property.location}</span>
                    </div>
                </div>
            </LinkComponent >
        </div>
    )
}

export default PropertyCard